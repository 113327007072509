/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { Field, Formik, Form } from "formik";
import MDInput from "components/MDInput";
import { MenuItem } from "@mui/material";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "layouts/utils/formaters.util";
import { useConfig } from "config";
function Dashboard() {
  const { apiUrl } = useConfig()
  const [controller] = useMaterialUIController()
  const { darkMode } = controller
  const [merchants, setMerchants] = useState([])
  const [activeMerchant, setActiveMerchant] = useState("all")
  const [volume, setVolume] = useState()
  const salesEmail = localStorage.getItem('sales_email')
  const salesToken = localStorage.getItem('sales_token')
  const salesApiKey = localStorage.getItem('sales_apiKey')
  // const { sales, tasks } = reportsLineChartData;
  const getAssignedMerchant = async (body) => {
    try {
      const response = await fetch(`${apiUrl}/sales/getMerchants`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'apiKey': salesApiKey,
          'authorization': salesToken
        },
        body: JSON.stringify(body)
      });
      const res = await response.json();
      if (res.responseCode === 403) {
        toast.error(res?.responseMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        localStorage.removeItem('user_token');
        navigate('/authentication/sign-in');
      }
      if (res?.responseCode !== 200) {
        return;
      }
      setMerchants(res?.responseData?.merchants)
    } catch (error) {
      // Display an error toast
    }
  };
  const getVolume = async (body) => {
    try {
      const response = await fetch(`${apiUrl}/sales/merchantVolume`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'apiKey': salesApiKey,
          'authorization': salesToken
        },
        body: JSON.stringify(body)
      });
      const res = await response.json();
      if (res?.responseCode !== 200) {
        return;
      }
      setVolume(res?.responseData?.merchantData)
    } catch (error) {
      // Display an error toast
    }
  };
  useEffect(() => {
    let volumeBody;
    if (activeMerchant !== "all") {
      volumeBody = {
        emailId: salesEmail,
        id: activeMerchant,
        all: false
      }
    } else {
      volumeBody = {
        emailId: salesEmail,
        all: true
      }
    }
    getVolume(volumeBody)
  }, [activeMerchant])

  useEffect(() => {
    let body = {
      emailId: salesEmail,
    }
    getAssignedMerchant(body)

  }, [])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mb={2}>
              <Formik
                initialValues={{ active_merchant: 'all' }}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <Field
                      type="text"
                      select
                      label="Select Merchant"
                      size="large"
                      as={MDInput}
                      fullWidth
                      name="active_merchant"
                      InputProps={{
                        classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                      }}
                      onChange={(e) => {
                        setFieldValue('active_merchant', e.target.value)
                        setActiveMerchant(e.target.value)
                      }}
                    >

                      {/* <MenuItem value="">Select Merchant</MenuItem> */}
                      <MenuItem value="all">All</MenuItem>
                      {merchants && merchants.map((merchant, index) => (
                        <MenuItem key={index} value={merchant?._id}>
                          {capitalizeFirstLetter(merchant?.business_name)}
                        </MenuItem>
                      ))}
                    </Field>


                  </Form>
                )}
              </Formik>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="7FDF9A"
                title="Today's Volume"
                count={volume?.last24hr}
              // percentage={{
              //   color: "success",
              //   amount: "+55%",
              //   label: "than lask week",
              // }}
              />
            </MDBox>
          </Grid>

          {/* <Grid item xs={12} md={2.4} lg={2.4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                // icon="leaderboard"
                title="Successful Transactions"
                color="D4BEF0"
                count={0}
              // percentage={{
              //   color: "success",
              //   amount: "+0%",
              //   label: "than last month",
              // }}
              />
            </MDBox>
          </Grid> */}
          {/* <Grid item xs={12} md={2.4} lg={2.4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="BBF2EA"
                // icon="store"
                title="Success Rate"
                count={0}
              // percentage={{
              //   color: "success",
              //   amount: "+0%",
              //   label: "than yesterday",
              // }}
              />
            </MDBox>
          </Grid> */}

          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="FFFED2"
                //icon="equalizer"
                title="Yesterday's Volume"
                count={volume?.yesterday}
              // percentage={{
              //   color: "success",
              //   amount: "",
              //   // label: "Just updated",
              // }}
              />
            </MDBox>
          </Grid>

          {/* <Grid item xs={12} md={2.4} lg={2.4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="C9DEDE"
                //icon="wallet"
                title="Wallet"
                count={0}
              // percentage={{
              //   color: "success",
              //   amount: "+0%",
              //   label: "than lask week",
              // }}
              />
            </MDBox>
          </Grid> */}
        </Grid>
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
